import $api from "../http";

export default class CodeService {
  static async activationCode(code, paymentData) {
    return $api.post("/activation", { code, paymentData });
  }

  static async validationCode(code) {
    return $api.get(`/validation/${code}`);
  }
}
