import React, { useEffect, useState } from "react";
import classes from "./Cookie.module.css";

const Cookie = ({ props }) => {
  const createCookie = (name, value, days) => {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  const getCookie = (c_name) => {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start !== -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end === -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  };

  /*const deleteCookie = (c_name) => {
    document.cookie = c_name + "=; Max-Age=-99999999;";
    }
    deleteCookie("CookieAgree");*/

  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    const _isAgree = getCookie("CookieAgree");
    if (_isAgree === "true") {
      setIsAgree(true);
    } else {
      setIsAgree(false);
    }
  }, [isAgree, setIsAgree]);

  const Agree = () => {
    createCookie("CookieAgree", true, 365);
    setIsAgree(true);
  };

  return !isAgree ? (
    <section className={classes.cookieBlock}>
      <img src="/imgs/Cookie.png" alt={""} className={classes.cookieImg} />
      <div className={classes.cookieText}>
        Наш сайт использует cookie–файлы для обеспечения должного функционала и
        улучшения работы сайта.
        <br />
        Продолжая использовать этот сайт, вы соглашаетесь с условиями
        использования cookie–файлов.
      </div>
      <button className={classes.CookieButton} type="button" onClick={Agree}>
        Согласен
      </button>
    </section>
  ) : (
    <></>
  );
};

export default Cookie;
