import React from "react";
import classes from "./FooterLegal.module.css";

const FooterLegal = () => {
  return (
    <div className={classes.FooterLegal}>
      <div className={classes.Copyright}>
        "Крипто Семечки"- вкусные и полезные жареные семечки для всей семьи! 
      </div>
      <div className={classes.Copyright}>©2024 Компания "Криптоснэк"</div>
      <div className={classes.LegalBlock}>
        <div className={classes.Copyright}>Юридическая информация:</div>
        <a
          className={classes.Legal}
          href={"/legal/user_agreement.pdf"}
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          Пользовательское соглашение
        </a>
        <a
          className={classes.Legal}
          href={"/legal/rules.pdf"}
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          Правила акции "Криптобонус"
        </a>
      </div>
    </div>
  );
};

export default FooterLegal;
