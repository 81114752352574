import React from "react";
import classes from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
  const navigate = useNavigate();

  const Navigation = (event) => {
    navigate("/" + event.target.value);
  };

  return (
    <nav className={classes.Navbar}>
      <button
        className={classes.NavButton}
        value={`#about`}
        onClick={Navigation}
      >
        О Нас
      </button>
      <div className={classes.Separator}></div>
      <button
        className={classes.NavButton}
        value={`production`}
        onClick={Navigation}
      >
        Продукция
      </button>
      <div className={classes.Separator}></div>
      <button
        className={classes.NavButton}
        value={`#promo`}
        onClick={Navigation}
      >
        Акции
      </button>
      <div className={classes.Separator}></div>
      <button
        className={classes.NavButton}
        value={`#partners`}
        onClick={Navigation}
      >
        Партнёрам
      </button>
      <div className={classes.Separator}></div>
      <button
        className={classes.NavButton}
        value={`#contacts`}
        onClick={Navigation}
      >
        Контакты
      </button>
    </nav>
  );
};

export default Navbar;
