import React, { Suspense, lazy } from "react";
import Loading from "../components/ui/loading/Loading";
const Main = lazy(() => import("../pages/Main"));
const LoginPage = lazy(() => import("../pages/Login"));
const Account = lazy(() => import("../pages/Account"));
const Product = lazy(() => import("../pages/Product"));
const Promos = lazy(() => import("../pages/Promos"));

export const publicRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <Main />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading />}>
        <LoginPage {...{ login: true, forgot: false }} />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/registration",
    element: (
      <Suspense fallback={<Loading />}>
        <LoginPage {...{ login: false, forgot: false }} />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/forgot",
    element: (
      <Suspense fallback={<Loading />}>
        <LoginPage {...{ login: false, forgot: true }} />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/account",
    element: (
      <Suspense fallback={<Loading />}>
        <Account />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/production",
    element: (
      <Suspense fallback={<Loading />}>
        <Product />
      </Suspense>
    ),
    exact: true,
  },
  {
    path: "/promos",
    element: (
      <Suspense fallback={<Loading />}>
        <Promos />
      </Suspense>
    ),
    exact: true,
  },
];

export const privateRoutes = [];
