import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import CodeService from "../services/CodeService";

export default class Store {
  user = {};
  isAuth = false;
  isLoading = false;
  codes = [];
  claimed = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setUser(user) {
    this.user = user;
  }

  setLoading(bool) {
    this.isLoading = bool;
  }

  setCodes(codes) {
    this.codes = codes;
  }

  setClaimed(claimed) {
    this.claimed = claimed;
  }

  async login(email, password) {
    try {
      const response = await AuthService.login(email, password);
      console.log(response);
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async registration(email, password, name) {
    try {
      const response = await AuthService.registration(email, password, name);
      console.log(response);
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      localStorage.removeItem("token");
      this.setAuth(false);
      this.setUser({});
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await AuthService.refresh();
      console.log(response);
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      this.setUser({});
      this.setAuth(false);
      console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  }

  async forgotPassword(email, name) {
    try {
      const response = await AuthService.forgotPassword(email, name);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async updateEmail(email, password, newEmail) {
    try {
      const response = await AuthService.updateEmail(email, password, newEmail);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async updatePassword(email, password, newPassword) {
    try {
      const response = await AuthService.updatePassword(
        email,
        password,
        newPassword
      );
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async getActivatedCodes() {
    try {
      const response = await AuthService.getActivatedCodes();
      this.setCodes(response.data.payouts);
      this.setClaimed(response.data.claimed);
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async validationCode(code) {
    try {
      const response = await CodeService.validationCode(code);
      return response.data;
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  async activationCode(code, paymentData) {
    try {
      const response = await CodeService.activationCode(code, paymentData);
      return response.data;
    } catch (e) {
      console.log(e.response?.data);
      return e.response?.data;
    }
  }
}
