import React from "react";
import classes from "./Rotator.module.css";

const Rotator = () => {
  return (
      <div className={classes["lds-roller"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default Rotator;