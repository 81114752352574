import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { /*privateRoutes ,*/ publicRoutes } from "../router";
import Loading from "../components/ui/loading/Loading";
const NotFound = lazy(() => import("../pages/NotFound.jsx"));

const AppRouter = () => {
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          element={route.element}
          path={route.path}
          exact={route.exact}
          key={route.path}
        />
      ))}
      <Route
        path="*"
        element={
          <Suspense fallback={<Loading />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRouter;
