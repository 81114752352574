import $api from "../http";

export default class AuthService {
  static async login(email, password) {
    return $api.post("/login", { email, password });
  }

  static async registration(email, password, name) {
    return $api.post("/registration", { email, password, name });
  }

  static async logout() {
    return $api.post("/logout");
  }

  static async refresh() {
    return $api.get("/refresh");
  }

  static getActivatedCodes() {
    return $api.get("/activatedcodes");
  }

  static async forgotPassword(email, name) {
    return $api.post("/forgotpassword", { email, name });
  }

  static async updateEmail(email, password, newEmail) {
    return $api.post("/updateemail", { email, password, newEmail });
  }

  static async updatePassword(email, password, newPassword) {
    return $api.post("/updatepassword", { email, password, newPassword });
  }
}
