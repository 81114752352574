import React, { useContext, useEffect } from "react";
import "./styles/App.css";
import AppRouter from "./components/AppRouter";
import { BrowserRouter } from "react-router-dom";
import { toJS } from "mobx";
import { Context } from "./context/Context";
import Cookie from "./components/ui/cookie/Cookie";
import Header from "./components/ui/header/Header";
import Footer from "./components/ui/footer/Footer";

function App() {
  const { store } = useContext(Context);

  useEffect(() => {
    async function checkAuth() {
      if (localStorage.getItem("token")) {
        await store.checkAuth();
      }
    }
    checkAuth();
  }, [store]);

  return (
    <Context.Provider
      value={{ isAuth: store.isAuth, user: toJS(store.user), store: store }}
    >
      <BrowserRouter>
        <Header />
        <AppRouter />
        <Cookie />
        <Footer />
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
