import React, { useContext } from "react";
import classes from "./Login.module.css";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";

const Login = (props) => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const navigateEvent = (event) => {
    navigate(event.target.value);
  };

  const logoutEvent = async () => {
    await store.logout();
    navigate("/");
  };

  const toAccount = () => {
    navigate("/account");
  };

  const NotLogin = (
    <div className={classes.loginDiv}>
      <button
        className={classes.logButton}
        value={"/login"}
        onClick={navigateEvent}
      >
        Логин
      </button>
      <div className={classes.regDiv}>
        <button
          className={`${classes.logButton} ${classes.regButton}`}
          value={"/registration"}
          onClick={navigateEvent}
        >
          Регистрация
        </button>
      </div>
    </div>
  );

  const TrueLogin = (props) => (
    <div className={classes.loginDiv}>
      <button className={classes.logButton} onClick={toAccount}>
        {props.email}
      </button>
      <img
        className={classes.logoutBut}
        alt=""
        src="/imgs/exit.svg"
        onClick={logoutEvent}
      />
    </div>
  );

  return store.isAuth ? TrueLogin(toJS(store.user)) : NotLogin;
};

export default observer(Login);
