import React from "react";
import classes from "./ProjectName.module.css";
import { useNavigate } from "react-router-dom";

const ProjectName = (props) => {
  const navigate = useNavigate();
  /*<div className={classes.ProjectName}>Крипто Семечки</div>*/
  return (
    <div className={classes.ProjectNameBlock}>
      <img
        className={classes.Logo}
        alt=""
        src="/imgs/crypto4.svg"
        onClick={() => {
          navigate("/");
        }}
      />
      <div className={classes.sloganImg}>
        <img className={classes.LogoName} alt="" src="/imgs/slogan-1.png" />
        <img className={classes.LogoName} alt="" src="/imgs/slogan-2.png" />
      </div>
    </div>
  );
};

export default ProjectName;
