import React from "react";
import classes from "./Loading.module.css";
import Rotator from "./rotator/Rotator";

const Loading = () => {
  return (
    <section className={classes.LoadingSection}>
      <Rotator />
    </section>
  );
};

export default Loading;
