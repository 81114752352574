import React from "react";
import classes from "./Header.module.css";
import Navbar from "../navbar/Navbar";
import Login from "../login/Login";
import ProjectName from "../projectName/ProjectName";

const Header = (props) => {
  return (
    <header className={classes.Header}>
      <ProjectName />
      <Navbar />
      <Login />
    </header>
  );
};

export default Header;
