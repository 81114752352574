import React from "react";
import classes from "./Footer.module.css";
import Navbar from "../navbar/Navbar";
import FooterLegal from "../footerLegal/FooterLegal";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className={classes.Footer}>
      <div className={classes.FooterBlock}>
        <img
          className={classes.Logo}
          src={"/imgs/logo.webp"}
          alt={""}
          onClick={() => {
            navigate("/");
          }}
        />
        <Navbar />
      </div>
      <div className={classes.FooterBlock}>
        <FooterLegal />
      </div>
    </footer>
  );
};

export default Footer;
