import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { store, Context } from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Context.Provider
    value={{
      store,
    }}
  >
    <App />
  </Context.Provider>
);
